import React, { Component } from "react"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"

import PropTypes from "prop-types"

import Typography from "@material-ui/core/Typography"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import classNames from "classnames"

import Grid from "@material-ui/core/Grid"

//iamges
import Industrial from "../pages/industrial.js"
import IndustrialServices from "../components/images/IndustrialServices"
import RemodelServices from "../components/images/RemodelServices"
import ResidentialServices from "../components/images/ResidentialServices"
import CommercialServices from "../components/images/CommercialServices"
import AdaServices from "../components/images/AdaServices"

const styles = theme => ({
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  // layout: {
  //   width: "auto",
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
  //     width: 1100,
  //     marginLeft: "auto",
  //     marginRight: "auto",
  //   },
  // },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",

    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 700,
    height: 600,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  overflow: { overflow: "hidden" },
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 600,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
})

function ServicesPage(props) {
  const { classes } = props

  return (
    <Layout>
      <SEO
        title=" Our Services"
        keywords={[
          `adc`,
          `American Dream Construction`,
          `construction`,
          `renovations`,
          `ada`,
          `remodels`,
          `commercial`,
          `industrial`,
          `services`,
          `alaska`,
          `reliable`,
          `storms`,
          `snow`,
          `steel`,
        ]}
      />
      <div className="container-body">
        <div className={classes.root}>
          <Typography variant="h4" style={{ textAlign: "center" }}>
            {" "}
            OUR SERVICES
          </Typography>

          <div className={classNames(classes.layout, classes.cardGrid)}>
            {/* End hero unit */}
            <Grid container spacing={40}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Link to="/industrial">
                  <Card className={classes.card}>
                    <IndustrialServices />

                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ textDecoration: "none" }}
                      >
                        Industrial
                      </Typography>
                      <Typography>
                        When winter storms get rough, ADC is here to put your
                        industrial buildings back together. With our
                        own rollformer and skilled crew, we can make
                        it so your buildings will have never looked better. We
                        also construct new industrial complexes and buildings.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {" "}
                      {/* <Button size="small" color="primary">
                      View
                    </Button> */}
                    </CardActions>
                  </Card>
                </Link>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Link to="/remodels">
                  <Card className={classes.card}>
                    <RemodelServices />
                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ textDecoration: "none" }}
                      >
                        Remodels
                      </Typography>
                      <Typography>
                        Instead of building a fresh new home, we can improve
                        your existing home. With our skilled crew, we can add a
                        garage, patio, or even an entire new floor, giving your
                        home the makeover it deserves.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {" "}
                      {/* <Button size="small" color="primary">
                      View
                    </Button> */}
                    </CardActions>
                  </Card>
                </Link>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Link to="/residential">
                  <Card className={classes.card}>
                    <ResidentialServices />

                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ textDecoration: "none" }}
                      >
                        Residential
                      </Typography>
                      <Typography>
                        American Dream has earned an outstanding reputation for
                        fine home-building and renovation. Complete design /
                        build capabilities, quality craftsmanship in every
                        detail, and clear, professional communication throughout
                        the process are all hallmarks of an American Dream
                        project.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {" "}
                      {/* <Button size="small" color="primary">
                      View
                    </Button> */}
                    </CardActions>
                  </Card>
                </Link>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Link to="/commercial">
                  <Card className={classes.card}>
                    <CommercialServices />
                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ textDecoration: "none" }}
                      >
                        Commercial
                      </Typography>
                      <Typography>
                        American Dream knows how hard it is to run your
                        business. That's why we ensure that your commercial
                        enterprise is built right the first time.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {" "}
                      {/* <Button size="small" color="primary">
                      View
                    </Button> */}
                    </CardActions>
                  </Card>
                </Link>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Link to="/ada">
                  <Card className={classes.card}>
                    <AdaServices />

                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ textDecoration: "none" }}
                      >
                        ADA
                      </Typography>
                      <Typography>
                        American Dream is up to date on the latest ADA federal
                        regulations, and can renovate or add specialized
                        accomodations for individuals with a variety of
                        additional needs. We go above and beyond, ensuring that
                        your home or place of residence is comfortable and
                        reliable. We have built ramps, graded showers,
                        accessible bathrooms, and much more in our over twenty
                        years of operation.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {" "}
                      {/* <Button size="small" color="primary">
                      View
                    </Button> */}
                    </CardActions>
                  </Card>
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Layout>
  )
}

ServicesPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(ServicesPage)
